@import url('https://fonts.googleapis.com/css2?family=DotGothic16&family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@600&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    background-color: black;
    width: 100%;
    height: 100%;
  overflow-x: hidden;

}

a{
    text-decoration: none;
    cursor: pointer;
    font-family: 'DotGothic16', cursive;
}

.App {
width: 100%;
 height: 100%;
 background-color: rgb(0, 0, 0);
overflow-x: hidden;
-ms-overflow-style: none;
scrollbar-width: none;
}

:root{
    --yellow: #FFC900;
    --blue:#2C63FF;
    --purple:#721DB3;
    --mixed: #2C63FF + #721DB3;

};

.hero-container{
  position: relative;
}
.hero-mainImg{
    opacity: 0.5;
    object-fit: cover;
    width: 100%;
    height: 800px;
    background-color: black;
}


.about-container{
    height: 100%;
    width: 100%;
    color: white;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.about-container h1,h2{
    font-family: 'DotGothic16', cursive;
   color: var(--yellow);

}
.about-container img{
    width: 290px;
    height: auto;
    margin-bottom: 1rem;
}

.about-container p{
    max-width: 80%;
    margin: 0 auto;
}

.logo{
    opacity: 1;
    z-index: 1000;
    width: 35px;
    height:35px;
    cursor: pointer;
  
}



.nav-header{
    position: absolute;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
}
.inhouse-logo{
    opacity: 1;
    z-index: 1000;
    cursor: pointer;
    width: 35px;
    height: 35px;
    background-color: var(--yellow);
    color: white;
    border-radius: 50%;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.divider{
    background:linear-gradient(rgba(0.6,0.6,0.6,0.6),rgba(0.6,0.6,0.6,0.6)), url('./utils//divider.png') no-repeat;
    width: 100%;
    height: 3rem;
    background-position: center;
    background-size: cover;
    margin: 3rem 0;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.about-stats div{
border-top: 2px solid #FFC900;
border-left: 2px solid #FFC900;
border-right: 2px solid #FFC900;
margin: 3rem auto;
border-radius: 10px;
max-width: 80%;
padding: 0.5rem;
}
.about-stats p{
    margin: 0.3rem auto;

}
.about-stats span{
    color: var(--yellow);
}
.about-container button{
  cursor: pointer;
    background-color: #FFC900;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.03rem;
    width: 12rem;
    height: 2.8rem;
    border-radius: 5px;
    margin: 0.25rem 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.about-container button:hover{
  background-color: #45a049;
}
.about-stats p, span{
    font-family: 'DotGothic16', cursive;
    letter-spacing: 1px;
}
.about-stats p{
    font-size: 1.2rem;
}
.community-container{
    width: 100%;
    height: 400px;
}
.footer{
    width: 100%;
    height: 400px;
}

.gallery{
    background: linear-gradient(rgba(0.4,0.4,0.4,0.4),rgba(0.4,0.4,0.4,0.4)) ,url('./utils/wall10.webp') no-repeat;
    width: 100%;
    height: 400px;
    background-position: center;
    background-size: cover;
}
.tokenomics-container{
    color: white;
    text-align: center;
    width: 100%;
    height: 100%;
    margin-top: 10rem;
    margin-bottom: 7rem;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.tokenomics-container img{
  object-fit: contain;
  width: 80%;
    height: 70px;
    margin-top: 1.5rem;
    cursor: pointer;
   
}
.roadmap-container{
    color: var(--yellow);
    width: 100%;
    height: 400px;
    background: black;
}

.howto-container{
  height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.howto-container h3{
    color: var(--yellow);
   border-bottom:1px solid #FFC900;
   margin: 0.5rem 0;
}

/* .cloud-img{
    top: 10%;
    left: 20%;
    z-index: 1000;
    position: absolute;
    width: 250px;
    height: 200px;
    background: url('./utils/yes.png') no-repeat  center center/cover;
} */

.hero-content{
  padding: 1rem;
    position: absolute;
    top: 10%;
    left: 6%;
    color: white;
}

.hero-container button{
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.03rem;
    width: 13rem;
    height: 3rem;
    border: none;
    border-radius: 5px;
    margin: 0.25rem 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.joinbtn{
  cursor: pointer;
   color: white;
   background-color: rgb(52, 165, 52);
}
.playbtn{
background-color: var(--yellow);
color: white;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
}

.icons{
    margin-top: 1rem;
    color: #229ED9;
  font-size: 30px;
  background-color: var(--yellow);
  padding: 0.5rem;
  border-radius: 50%;
  
}
.nav-menu {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: black;
    color: white;
    transition: all 0.5s linear;
    transform: translateX(100%);
    z-index: 10000;
  }
.nav-link-item{
    margin-top: 0.75rem;
    font-size: 1.2rem;
    letter-spacing: 1px;
    color: white;
}

.close-btn{
    margin: 1rem 0;
    cursor: pointer;
}

.close-btn:hover{
    transform: rotate(45deg);
}

.nav-link-item:hover{
    color: #721DB3;
    border-bottom: 1px solid white;
}

.show-nav {
    transition: all 0.3s linear;
    transform: translateX(0%);
  }

  .oggy-description{
    text-align: center;
    font-family: 'DotGothic16', cursive;
    color: var(--yellow);
    padding: 1rem;
    margin-top: 3rem;
  }
  .oggy-description p{
    max-width: 80%;
    margin: 0.5rem auto;
    letter-spacing: 1px;
    line-height: 26px;
  }
  
  .roadmap {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    padding: 20px;
  }
  
  .box {
    background: linear-gradient(145deg, #1e1e1e, #242424);
    color: #FFC900;
    margin: 20px 0;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 90%;
    max-width: 700px; /* Adjust the max-width as needed */
  }
  
  .box:hover {
    transform: translateY(-5px);
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.6);
  }
  
  .box h2 {
    background: #FFC900; /* Giving a background color */
    color: #1e1e1e; /* Dark text color for contrast */
    margin: 0 auto; /* Centers the title and provides space from the box edges */
    padding: 10px 20px; /* Padding around the text */
    border-radius: 5px; /* Rounded corners for the background */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
    display: inline-block; /* Allows us to set padding and background */
    transform: translateY(-50%); /* Moves the title up to straddle the box edge */
    position: relative; /* Needed for the translateY to work */
    top: -20px; /* Adjusts the placement of the title */
    text-shadow: 1px 1px 2px black; /* Subtle text shadow for depth */
    font-size: 1.5em;
  }
  
  .box ul {
    list-style: inside;
    padding: 0;
    margin: 20px 0;
  }
  
  .box ul li {
    margin-bottom: 10px;
    line-height: 1.5;
    text-align: left;
  }
  
  /* Adding a class for the list item to control the bullet color */
  .box ul li::before {
    content: "• ";
    color: #FFC900; /* Bullet color */
    font-size: 1.2em;
    line-height: 0;
    vertical-align: middle;
    margin-right: 5px;
  }
  
  .footer {
    height: 100%;
    margin-top: 3rem;
    color: #FFC900; /* Yellow text color */
    display: flex;
    flex-direction: column; /* Default to column layout for mobile */
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    position: relative;
    text-align: center;
    background: linear-gradient(180deg, rgba(10, 10, 10, 0.8) 0%, rgba(10, 10, 10, 0.9) 100%), 
                url('./utils//background.webp'); /* Linear gradient over your image */
    background-size: cover; /* Ensure the background covers the footer */
    background-position: center; /* Center the background image */
  }
  
  .footer-column {
    margin: 10px;
    padding: 20px;
  }
  
  .footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
  
  .footer-column h3 {
    margin-bottom: 15px;
    font-size: 22px;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .footer-column li{
    margin: 0.5rem 0;
  }
  .footer-column ul li a {
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease;
    color: var(--yellow);
    cursor: pointer;
    letter-spacing: 1px;
  }
  
  .footer-column ul li a:hover {
    color: white;
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  
  /* Responsive layout for tablets and desktops */
  @media (min-width: 768px) {
    .footer {
      flex-direction: row; /* Switch to row layout */
      align-items: flex-start;
      text-align: left;
    }
  
    .footer-column {
      flex: 1; /* Equal width for each column */
    }
  }
  

  .bottom-fot {
    text-align: center;
    color: #666;
    padding: 20px 0;
    font-size: 0.75rem;
    border-top: 1px solid #333;
    margin-top: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
   
  }
  
  /* Glödande texteffekt för bottom-fot */
  .bottom-fot p{
    display: inline-block;
    color: #FFF;
    text-shadow: 0 0 10px #FFC900, 0 0 20px #FFC900; /* Textskugga som ger en glödande effekt */
    animation: glow 2s ease-in-out infinite alternate;
  }
  
  /* Nyckelframes för glödande textanimation */
  @keyframes glow {
    from {
      text-shadow: 0 0 10px #FFC900, 0 0 20px #FFC900, 0 0 30px #FFC900;
    }
    to {
      text-shadow: 0 0 20px #FFC900, 0 0 30px #FFC900, 0 0 40px #FFC900;
    }
  }
  .bottom-fot p{
    margin: 0.35rem 0;
    letter-spacing: 0.5px;
  }

  .oggyprofits {
    background-color: #121212; /* Dark background */
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0 4px 20px rgba(255, 201, 0, 0.5); /* Yellow-gold glow */
    color: #FFC900; /* Theme yellow color for text */
    padding: 40px; /* Padding around content */
    max-width: 800px; /* Maximum width of the component */
    margin: 40px auto; /* Centering in the middle of the screen */
    text-align: center; /* Center text alignment */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions for hover */
  }
  
  .oggyprofits:hover {
    transform: translateY(-10px); /* Lift component on hover */
    box-shadow: 0 8px 30px rgba(255, 201, 0, 0.6); /* Intensify glow on hover */
  }
  
  .oggyprofits h2 {
    margin-bottom: 20px; /* Space below the header */
    font-size: 2em; /* Larger font size for the header */
    color: var(--yellow); /* White color for contrast */
    text-transform: uppercase; /* Uppercase text for styling */
    letter-spacing: 2px; /* More spacing between letters */
  }
  
  .oggyprofits p {
    font-size: 1em; /* Appropriate font size for readability */
    line-height: 1.6; /* Spacing between lines for readability */
    margin-bottom: 30px; /* Space below the paragraph */
    color: #E0E0E0; /* Light grey color for the paragraph */
    font-family: 'DotGothic16', cursive;
  }
  
  .oggyprofits img {
    max-width: 100%; /* Ensure image is not bigger than its container */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Rounded corners for the image */
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3); /* Subtle shadow for the image */
  }
  .whitepaper-sub{
    margin: 1rem 0;
  }
  .whitepaper-sub span{
    font-size: 0.8rem;
    font-family: 'Courier New', Courier, monospace;
  }
  .whitepaper-sub h3{
    margin-bottom: 0.3rem;
  }
  .whitepaper-sub p{
    max-width: 500px;
  }
.whitepaper-menu{
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem
}
.whit-con-latest{
  color: var(--yellow);
  padding: 0.75rem;
}
.whit-con-latest a{
  color: white;
}
.whit-con-latest a:hover{
  color: var(--yellow);
}

/*  */
.game-presentation-container {
  margin: 3rem 0;
  display: flex;
  flex-direction: column; /* Ändra till kolumnlayout på mindre skärmar */
  align-items: center;
  padding: 40px;
  background-color: #000;
  color: #ffc900;
}

.game-info {
  text-align: center; /* Justera textcentrering för mobila enheter */
  margin-bottom: 20px;
}

.game-info  h2 {
  color: #ffc900;
}

.game-info  p {
  color: #ffc900;
  max-width: 600px;
  margin: 20px 0;
  line-height: 28px;
}

.explore-button {
  background-color: #ffc900;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.explore-button:hover {
  background-color: #45a049;
}

.game-gif {
  margin-bottom: 20px; /* Justera mellanrummet mellan text och bild för mobila enheter */
}

.game-gif img {
  border: 5px solid #ffc900;
  max-width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  /* Anpassningar för skärmar som är 768px eller större (tablet och desktop) */
  .game-presentation-container {
    flex-direction: row; /* Återgå till radlayout på större skärmar */
    justify-content: space-between;
  }

  .game-info {
    text-align: left;
  }

  .game-gif {
    margin-bottom: 0;
    margin-left: 40px;
  }
}

/*  */

.video-presentation-container {
  margin: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  background-color: #1f1f1f;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.1);
}

.video-wrapper {
  width: 100%;
  max-width: 800px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
  transition: transform 0.3s ease;
}

.react-youtube {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.video-wrapper:hover .react-youtube {
  transform: scale(1.05);
}

.video-info {
  text-align: center;
}

.video-presentation-container h2 {
  margin-top: 1rem;
  color: #ffc900;
  margin-bottom: 10px;
}

.video-presentation-container p {
  color: #ccc;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.5;
}

/* Extra effekter för presentation-container */
.video-presentation-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(31, 31, 31, 0), rgba(31, 31, 31, 0.8)); /* Gradient över bakgrund */
  z-index: -1;
}

@media (max-width: 768px) {
  .video-presentation-container {
    padding: 20px;
  }
}

/*  */
.social-icons-container{
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s linear;
}
.social-icons-container img{
  height: 100px;
cursor: pointer;
transition: all 0.3s linear;
}
.social-icons-container img:hover{
  transform: translateY(-10px);
}
.career-container{
  height: 100vh;
  width: 100vw;
  color: var(--yellow);
}
.career-footer{


}
.charity-container {
  background-color: #f4f4f4;
  color: #333;
  font-family: 'Arial', sans-serif;
  padding: 20px;
}

.charity-hero {
  background: linear-gradient(
                rgba(0, 90, 156, 0.6), 
                rgba(0, 90, 156, 0.6)),
              url('./utils//charit-oggyfloki.webp') no-repeat center;
  background-size: cover;
  color: #ffffff;
  text-align: center;
  padding: 40px 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.charity-hero h1, .charity-hero h2 {
  margin: 0;
  padding: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  font-size: 2em; /* Larger font size */
  animation: fadeIn 2s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}


.charity-partners, .charity-support, .charity-join {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.charity-partners h2, .charity-support h2, .charity-join h2 {
  color: #005a9c; /* Matching the hero background */
  margin-bottom: 20px;
}

.charity-partners li, .charity-support p, .charity-join p {
  font-size: 1.1em;
  line-height: 1.6;
}

.donate-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 15px 32px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 20px;
}

.donate-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

/* Responsive design */
@media (max-width: 768px) {
  .charity-hero h1, .charity-hero h2 {
    font-size: 1.5em;
  }

}


.nav-header button{
  width: 100%;
  font-size: 0.9rem;
}

.fra-swap{
  width: 400px;
  height: 640px;
  border: none;
  border-radius: 10px;;
}
.screendesk{
  position: absolute;
  right: 8%;
  top: 20%;
}
.screendesk h1{
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  color: white;
  font-size: 68px;
  max-width: 500px;
  line-height: 100%;
  display: inline-block;
  margin-bottom: 2rem;
}
.screendesk button{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
border-radius: 25px;
margin: 0.25rem 0.5rem;
width: 11rem;
cursor: pointer;
font-size: 1.1rem;
}
.screendesk button:hover{
  background-color: transparent;
  color: var(--yellow);
  border: 1px solid #FFC900;
}

@media (max-width: 982px) {
  .fra-swap-cont{
    display: none;
  }
  .screendesk{
    left: 5%;
    top: 20%;
  }
  .hero-ikoner-class{
    position: absolute;
    top: 1500%;
  }
  .screendesk h1{
    font-size: 60px;
  }
}
.tokenmi-btn{
  background-color: #FFC900;
  color: white;
}
.buy-btn-hero-louie{
  background-color: green;
  color: white;
}